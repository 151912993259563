import book0 from "./book0.png";
import book1 from "./book1.png";
import book2 from "./book2.png";
import book3 from "./book3.png";
import book4 from "./book4.png";

import lang0 from "./lang0.png";
import lang1 from "./lang1.png";
import lang2 from "./lang2.png";
import lang3 from "./lang3.png";
import lang4 from "./lang4.png";

import proj0 from "./proj0.png";
import proj1 from "./proj3.png";
import proj2 from "./proj1.png";
import proj3 from "./proj2.png";
import proj4 from "./proj4.png";
import proj5 from "./proj5.png";

import face0 from "../images/face0.png";
import face1 from "../images/face1.png";
import face2 from "../images/face2.png";
import face3 from "../images/face3.png";
import face4 from "../images/face4.png";
import face5 from "../images/face5.png";
import face6 from "../images/face6.png";

export const bookList = [book0, book1, book2, book3, book4];
export const bookCaps = [
  "Godel, Escher, Bach by Douglas Hofstadter",
  "Freakonomics by Stephen J. Dubner and Steven Levitt",
  "The Stranger by Albert Camus",
  "Metamagical Themas by Douglas Hofstadter",
  "The Hitchhiker's Guide to the Galaxy by Douglas Adams",
];

export const langList = [lang0, lang1, lang2, lang3, lang4];
export const langCaps = ["Python", "C++", "Javascript", "React", "Lua"];

export const projList = [proj5, proj4, proj0, proj1, proj2];
export const projCaps = [
  [
    "Sandtris",
    "A casual falling block game using my custom sand automata engine. Made using P5.js",
  ],
  [
    "Hypixel Bazaar Flip Calculator",
    "A trading tool that uses Hypixel’s Bazaar data API to rank and calculate the best items to flip on their online market.",
  ],
  [
    "Logic Table Generator",
    "A web app that parses logical sentences and produces their full logic table. The app also checks joint satisfiablity and aggregates all logical statements into one combined table. Made with React.",
  ],
  [
    "WikiRumble",
    "A web based casual trivia game that puts random Wikipedia articles head-to-head. This game uses the Wikipedia API and was made using React.",
  ],
  [
    "TheseNutsDoNotExist",
    "A humourous parody of ThisPersonDoesNotExist.com. This site displays a random picture of nuts (such as cashews, walnuts, etc.) generated by Stable Diffusion, along with its metadata. Made using just HTML, CSS, and Javascript.",
  ],
];
export const projLinks = [
  "https://sandtris.com/",
  "https://fanrco.github.io/HypixelBazaarFlipCalculator/",
  "https://fanrco.github.io/LogicTables/",
  "https://fanrco.github.io/WikiRumble/",
  "https://www.thesenutsdonotexist.com/",
];

export const faceAnim = [
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,

  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face0,
  face1,
  face0,
  face0,
  face0,
  face2,
  face0,
  face0,
  face0,
  face0,
  face0,
  face1,
  face2,
  face3,
  face4,
  face5,
  face6,
  face0,
  face3,
  face5,
  face3,
];
